<template>
  <div class="clearBox">
    <div class="content-box" style="padding-top: 24px">
      <ayl-berad-nav :nav="nav"></ayl-berad-nav>
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">合同信息</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 24px">
            <el-form
              :model="form"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="124px"
            >
              <!-- 合同名称 -->
              <el-form-item label="合同名称：">
                <span>{{ form.contractName |isNull }}</span>
              </el-form-item>
              <el-form-item label="合同编号：">
                <span>{{ form.contractNum |isNull }}</span>
              </el-form-item>
              <!-- 中标日期 -->
              <el-form-item label="中标日期：">
                <span>{{ form.getBidDate |isNull |str2ymd}}</span>
              </el-form-item>
              <el-form-item label="合同金额：">
                <span>{{ form.contractFund |isNull }}</span>
              </el-form-item>
              <el-form-item label="合同签订方式：">
                <span>{{ form.signTypeDes |isNull }}</span>
              </el-form-item>
              <el-form-item label="合同年限：">
                <span>{{ form.contractLimitDes |isNull }}</span>
              </el-form-item>
              <el-form-item label="服务开始日期：">
                <span>{{ form.contractStartTime |isNull|str2ymd}}</span>
              </el-form-item>
              <el-form-item label="服务终止日期：">
                <span>{{ form.contractEndTime |isNull |str2ymd}}</span>
              </el-form-item>
              <el-form-item label="甲方单位：">
                <span>{{ form.partyAUnit |isNull }}</span>
              </el-form-item>
              <el-form-item label="标段名称：">
                <span>{{ form.bidName |isNull }}</span>
              </el-form-item>
              <el-form-item label="甲方项目联系人：">
                <span>{{ form.partyAContact |isNull }}</span>
              </el-form-item>
              <el-form-item label="甲方联系方式：">
                <span>{{ form.partyAPhone |isNull }}</span>
              </el-form-item>
              <el-form-item label="乙方项目联系人：">
                <span>{{ form.partyBContact |isNull }}</span>
              </el-form-item>
              <el-form-item label="乙方联系方式：">
                <span>{{ form.partyBPhone |isNull }}</span>
              </el-form-item>
              <el-form-item label="所属企业：">
                <span>{{ form.companyId |isNull }}</span>
              </el-form-item>
              <el-form-item style="width: 100%" label="合同附件：">
                <ayl-img-upload
                  v-model="contractImageUrls"
                  :imgWidth="'120px'"
                  :imgHeight="'120px'"
                  :imgListLength="50"
                  :isEdit="false"
                ></ayl-img-upload>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div class="tac mt40px">
        <!-- <el-button type="success" class="btn-success-hw" @click="create_order(0)">保存</el-button> -->
        <el-button type="danger" class="btn-danger" style="margin-left: 20px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BUS, BUSEVENT } from "@/components/global/bus.js";
export default {
  data() {
    return {
      nav: [{ name: "标段合同详情" }],
      debug: 0,
      form: {},
      contractImageUrls: []
    };
  },
  filters: {
    isNull: value => {
      return value !== 0 && !value ? "--" : value;
    }
  },
  methods: {
    onClose() {
      BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
    }
  },
  computed: {},
  async activated() {
    let formData = await this.$api_hw.districtInfo_contractGet({
      id: this.$route.query.id
    });
    this.form = formData;
    this.contractImageUrls = formData.contractImageUrls.splice(",");
    this.log(this.form);
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .tips
    width: 407px
    height: 22px
    margin-left: 170px
    font-size: 12px
    font-family: Microsoft YaHei
    font-weight: 400
    color: rgba(255,140,26,1)
    line-height: 32px
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 30%

</style>
